import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { HammerModule } from '@angular/platform-browser';
import { NotificationsModule } from 'src/app/ui/notifications/notifications.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from './store/store.module';
import { AppCommonModule } from './common/common.module';
import { ApiErrorsModule } from './modules/api-errors/api-errors.module';
import { CustomCurrencyModule } from './ui/currency/custom-currency.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    StoreModule,
    AppCommonModule,
    ApiErrorsModule,
    CustomCurrencyModule,
    NotificationsModule,
    NgxPageScrollCoreModule,
    HammerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}