import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { Subject, takeUntil, timer } from 'rxjs';
import { SnackBarData } from './snack-bar-data.interface';

@Component({
  selector: 'ik-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent implements OnDestroy {
  @Input() 
  public data!: SnackBarData;
  private destroy$ = new Subject<void>();

  constructor() {
    timer(12000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.destroy$.next());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
