export enum ThanksTypeEnum {
  THANKS_WITH_WORKHEETS = 'THANKS_WITH_WORKHEETS',
  AFTER_WORKSHEETS = 'AFTER_WORKSHEETS',
  INFO_MB = 'INFO_MB',
  INFO_3 = 'INFO_3',
  INFO_WORKSHEETS = 'INFO_WORKSHEETS',
  INFO_E4K = 'INFO_E4K',
  PHONE_NUMBER = 'PHONE_NUMBER',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
}
