import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SnackBarData } from './snack-bar/snack-bar-data.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notificationsSubject = new Subject<SnackBarData>();
  public notifications$ = this.notificationsSubject.asObservable();

  public warning(message: string, matIcon = 'warning'): void {
    this.custom(message, matIcon, '#ff6d00');
  }

  public success(message: string, matIcon = 'success'): void {
    this.custom(message, matIcon, '#ff6d00');
  }

  public custom(message: string, matIcon: string, color: string): void {
    this.notificationsSubject.next({
      message,
      matIcon,
      color,
      id: Date.now() // Добавляем уникальный ID для каждого уведомления
    });
  }
}
