import { Action } from '@ngrx/store';
import { AuthChallengeResponseJson } from 'src/app/common/json/auth-challenge-response.json-interface';
import { AuthResponseJson } from 'src/app/common/json/auth-response.json-interface';
import { DeepLink } from 'src/app/common/models/deeplink';
import { Product } from 'src/app/common/models/product';

import { Payment } from './../../common/models/payment';
import { PaymentViewOffer } from './../../common/models/payment-view-offer';

export enum EUserInfoActions {
  GetUserInfoPayment = '[UserInfo] Get UserInfo Payment',
  GetUserInfoPaymentSuccess = '[UserInfo] Get UserInfo Payment Success',
  GetUserInfoEmail = '[UserInfo] Get UserInfo Email',
  GetUserInfoEmailSuccess = '[UserInfo] Get UserInfo Email Success',
  GetUserInfoProduct = '[UserInfo] Get UserInfo Product',
  GetUserInfoProductSuccess = '[UserInfo] Get UserInfo Product Success',
  GetUserInfoChallenge = '[UserInfo] Get UserInfo Challenge',
  GetUserInfoChallengeSuccess = '[UserInfo] Get UserInfo Challenge Success',
  GetUserInfoAuth = '[UserInfo] Get UserInfo Auth',
  GetUserInfoAuthSuccess = '[UserInfo] Get UserInfo Auth Success',
  GetUserInfoBrowserID = '[UserInfo] Get UserInfo BrowserID',
  GetUserInfoBrowserIDSuccess = '[UserInfo] Get UserInfo BrowserID Success',
  GetUserInfoPromoter = '[UserInfo] Get UserInfo Promoter',
  GetUserInfoPromoterSuccess = '[UserInfo] Get UserInfo Promoter Success',
  GetUserInfoUpgrade = '[UserInfo] Get UserInfo Upgrade',
  GetUserInfoUpgradeSuccess = '[UserInfo] Get UserInfo Upgrade Success',
  UpdateDeepLinksSuccess = '[UserInfo] Update DeepLinks Success',
  UpdateUserInfoPurchases = '[UserInfo] Update Purchases',
  UpdateCounter = '[UserInfo] Update Woksheets Time',
  UpdateChildName = '[UserInfo] Update Child Name',
  UpdateChildAge = '[UserInfo] Update Child Age',
  UpdateInterests1 = '[UserInfo] Update Interests1',
  UpdateInterests2 = '[UserInfo] Update Interests2',
  ChangeSubscriptionSwitch = '[UserInfo] Change Subscription Switch',
  UpdateAnswersMap = '[UserInfo] Update Answers Map',
  UpdateAnswersKeyMap = '[UserInfo] Update Answers Key Map',
  SetSource = '[UserInfo] Set Source',
  UpdateNewFlow = '[UserInfo] Update New Flow',
}

export class GetUserInfoPayment implements Action {
  public readonly type = EUserInfoActions.GetUserInfoPayment;
  constructor(public payload: Payment) {}
}

export class GetUserInfoPaymentSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoPaymentSuccess;
  constructor(public payload: Payment) {}
}

export class GetUserInfoEmail implements Action {
  public readonly type = EUserInfoActions.GetUserInfoEmail;
  constructor(public payload: string | null) {}
}

export class GetUserInfoEmailSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoEmailSuccess;
  constructor(public payload: string) {}
}

export class GetUserInfoProduct implements Action {
  public readonly type = EUserInfoActions.GetUserInfoProduct;

  constructor(public payload: { product: Product | null, offerView: PaymentViewOffer | null }) {
  }
}

export class GetUserInfoProductSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoProductSuccess;
  constructor(public payload: { product: Product | null, offerView: PaymentViewOffer | null }) {
  }
}

export class GetUserInfoChallenge implements Action {
  public readonly type = EUserInfoActions.GetUserInfoChallenge;
  constructor(public payload: AuthChallengeResponseJson) {}
}

export class GetUserInfoChallengeSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoChallengeSuccess;
  constructor(public payload: AuthChallengeResponseJson) {}
}

export class GetUserInfoAuth implements Action {
  public readonly type = EUserInfoActions.GetUserInfoAuth;
  constructor(public payload: AuthResponseJson) {}
}

export class GetUserInfoAuthSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoAuthSuccess;
  constructor(public payload: AuthResponseJson) {}
}

export class GetUserInfoBrowserID implements Action {
  public readonly type = EUserInfoActions.GetUserInfoBrowserID;
  constructor(public payload: string) {}
}

export class GetUserInfoBrowserIDSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoBrowserIDSuccess;
  constructor(public payload: string) {}
}

export class GetUserInfoPromoter implements Action {
  public readonly type = EUserInfoActions.GetUserInfoPromoter;
  constructor(public payload: string) {}
}

export class GetUserInfoPromoterSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoPromoterSuccess;
  constructor(public payload: string) {}
}

export class GetUserInfoUpgrade implements Action {
  public readonly type = EUserInfoActions.GetUserInfoUpgrade;
  constructor(public payload: boolean) {}
}

export class GetUserInfoUpgradeSuccess implements Action {
  public readonly type = EUserInfoActions.GetUserInfoUpgradeSuccess;
  constructor(public payload: boolean) {}
}

export class UpdateDeepLinksSuccess implements Action {
  public readonly type = EUserInfoActions.UpdateDeepLinksSuccess;
  constructor(public payload: DeepLink[]) {}
}

export class UpdatePurchases implements Action {
  public readonly type = EUserInfoActions.UpdateUserInfoPurchases;
  constructor(public payload: Product[]) {}
}

export class UpdateCounter implements Action {
  public readonly type = EUserInfoActions.UpdateCounter;
  constructor(public payload: number) {}
}

export class UpdateChildName implements Action {
  public readonly type = EUserInfoActions.UpdateChildName;
  constructor(public payload: string) {}
}

export class UpdateChildAge implements Action {
  public readonly type = EUserInfoActions.UpdateChildAge;
  constructor(public payload: string) {}
}

export class UpdateInterests1 implements Action {
  public readonly type = EUserInfoActions.UpdateInterests1;
  constructor(public payload: string[]) {}
}

export class UpdateInterests2 implements Action {
  public readonly type = EUserInfoActions.UpdateInterests2;
  constructor(public payload: string[]) {}
}

export class ChangeSubscriptionSwitch implements Action {
  public readonly type = EUserInfoActions.ChangeSubscriptionSwitch;
  constructor(public payload: boolean) {}
}

export class UpdateAnswersMap implements Action {
  public readonly type = EUserInfoActions.UpdateAnswersMap;
  constructor(public payload: { category: string, answer: string }) {}
}

export class UpdateAnswersKeyMap implements Action {
  public readonly type = EUserInfoActions.UpdateAnswersKeyMap;
  constructor(public payload: { key: string, answer: string | string[] }) {}
}

export class SetSource implements Action {
  public readonly type = EUserInfoActions.SetSource;
  constructor(public payload: string) {}
}

export class UpdateNewFlow implements Action {
  public readonly type = EUserInfoActions.UpdateNewFlow;
  constructor(public payload: boolean) {}
}

export type UserInfoActions =
  GetUserInfoPayment |
  GetUserInfoPaymentSuccess |
  GetUserInfoEmail |
  GetUserInfoEmailSuccess |
  GetUserInfoProduct |
  GetUserInfoProductSuccess |
  GetUserInfoChallenge |
  GetUserInfoChallengeSuccess |
  GetUserInfoAuth |
  GetUserInfoAuthSuccess |
  GetUserInfoBrowserID |
  GetUserInfoBrowserIDSuccess |
  GetUserInfoPromoter |
  GetUserInfoPromoterSuccess |
  GetUserInfoUpgrade |
  GetUserInfoUpgradeSuccess |
  UpdateDeepLinksSuccess |
  UpdatePurchases |
  UpdateCounter |
  UpdateChildName |
  UpdateChildAge |
  UpdateInterests1 |
  UpdateInterests2 |
  ChangeSubscriptionSwitch |
  UpdateAnswersMap |
  UpdateAnswersKeyMap |
  SetSource |
  UpdateNewFlow;
