import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/ui/notifications/notifications.service';
import { SnackBarData } from './../snack-bar/snack-bar-data.interface';

@Component({
  selector: 'ik-notifications-container',
  template: `
    <ik-snack-bar 
      *ngFor="let notification of notifications"
      [data]="notification">
    </ik-snack-bar>
  `,
  styles: [`
    :host {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }
  `]
})
export class NotificationsContainerComponent implements OnInit, OnDestroy {
  public notifications: SnackBarData[] = [];
  private subscription!: Subscription;

  constructor(private notificationsService: NotificationsService) {}

  public ngOnInit(): void {
    this.subscription = this.notificationsService.notifications$
      .subscribe(notification => {
        this.notifications = [...this.notifications, notification];
        setTimeout(() => {
          this.notifications = this.notifications.filter(
            n => n.id !== notification.id
          );
        }, 12000);
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
