import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ErrorHandler } from '@angular/core';
import { CustomTranslateLoader } from '../common/services/custom-translate-loader.service';
import { MyMissingTranslationHandler, NotTranslatedService } from '../modules/not-translated/translation-handler.service';
import { environment } from '../../environments/environment.prod';
import { API_URL_GATEWAY } from '../api-service.config';
import { GlobalErrorHandler, ErrorLogService } from '../common/services/error-log.service';
import { ApiErrorsInterceptor } from '../modules/api-errors/api-errors.interceptor';
import { TitleService } from '../ui/title/title.service';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
        deps: [NotTranslatedService],
      },
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    TitleService,
    {
      provide: API_URL_GATEWAY,
      useValue: environment.gateway,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
    ErrorLogService,
    NotTranslatedService,
  ],
})
export class CoreModule {}
