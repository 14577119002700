import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { StorageService } from 'src/app/common/services/storage.service';
import { IAppState } from 'src/app/store/states/app.state';

import { FONTS } from './common/constants/fonts';
import { AnalyticTypesEnum } from './common/enums/analytic-types.enum';
import { AnalyticService } from './common/services/analytic.service';
import { AuthService } from './common/services/auth.service';
import { SubscriptionService } from './common/services/subscription.service';
import {
  GetUserInfoEmail,
  GetUserInfoUpgrade,
} from './store/actions/user-info.actions';
import {
  GetSpecialVariantFromServer,
  GetVariantByBrowserId,
  GetVariantByType,
  UpdateAndChangeVariant,
} from './store/actions/variant.actions';

import {
  VARIANT_BY_TYPE_CONFIG,
  VARIANT_FROM_SERVER_CONFIG,
  DEFAULT_VARIANT_ID,
} from './common/constants/variant-config';

@Injectable()
export class AppService {
  constructor(
    private readonly storageService: StorageService,
    private readonly store: Store<IAppState>,
    private readonly meta: Meta,
    private readonly authService: AuthService,
    private readonly subscriptionService: SubscriptionService,
    private readonly analyticService: AnalyticService
  ) {}

  public setVariant(): void {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const storageVariant = this.storageService.getVariant();
    const variantId = new URLSearchParams(window.location.search).get('variant_id');

    // Если есть сохраненный вариант и нет @ в пути
    if (storageVariant && pathname.indexOf('@') === -1) {
      this.resetCookies();
      this.store.dispatch(new UpdateAndChangeVariant(storageVariant));
      return;
    }

    // Специальный случай для welcome.intellectokids.com
    if (origin.includes('welcome.intellectokids.com')) {
      this.store.dispatch(
        new GetSpecialVariantFromServer('3d47452c-1fd9-4931-8170-6f42634e88b7')
      );
      return;
    }

    // Если есть variant_id в query-параметрах
    if (variantId) {
      this.store.dispatch(new GetSpecialVariantFromServer(variantId));
      return;
    }

    // Проверяем варианты по типу (GetVariantByType)
    const variantByType = VARIANT_BY_TYPE_CONFIG.find(config => pathname.includes(config.path));
    if (variantByType) {
      this.store.dispatch(
        new GetVariantByType(this.storageService.getUserId(), variantByType.variantType!)
      );
      return;
    }

    // Проверяем варианты с сервера (GetSpecialVariantFromServer)
    const variantFromServer = VARIANT_FROM_SERVER_CONFIG.find(config => pathname.includes(config.path));
    if (variantFromServer) {
      this.store.dispatch(
        new GetSpecialVariantFromServer(variantFromServer.variantId!)
      );
      return;
    }

    // Специальная обработка для /pay с userId
    if (pathname.includes('/pay') && this.storageService.userIdExist()) {
      this.store.dispatch(
        new GetVariantByBrowserId(this.storageService.getUserId())
      );
      return;
    }

    // Дефолтный случай
    this.store.dispatch(new GetSpecialVariantFromServer(DEFAULT_VARIANT_ID));
  }

  // public insertHotjar(): void {
  //   const node = document.createElement('script');
  //   node.innerHTML = window.location.href.indexOf('education4kids.online') !== -1 ? this.hotjarForEducation() : this.hotjarForWelcome();
  //   document.getElementsByTagName('head')[0].appendChild(node);
  // }

  public updateTag(): void {
    if (window.location.href.indexOf('welcome.intellectokids') !== -1) {
      this.meta.updateTag({
        name: 'facebook-domain-verification',
        content: 'v6ij2s4fju326c0dnde358tz9yhzud',
      });
    }
  }

  public authByEmail(email: string): void {
    // const email = new URLSearchParams(window.location.search).get('email');
    this.authService.signUp(email).subscribe(() => {
      this.store.dispatch(new GetUserInfoUpgrade(false));
      this.getSubscriptions();
      this.store.dispatch(new GetUserInfoEmail(email));
      this.analyticEvent();
    });
  }

  public checkCookie(): void {
    if (this.getCookie('visitCounter')) {
      document.cookie = `visitCounter=${+this.getCookie('visitCounter') + 1}`;
      return;
    }
    document.cookie = 'visitCounter=1';
  }

  public insertFonts(): void {
    const node = document.createElement('style');
    node.innerHTML = FONTS;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public insertBanner(): void {
    const node = document.createElement('script');
    node.src = 'https://cdn-cookieyes.com/client_data/c048d62374eaa13f6ffadfe5/script.js';
    node.id = 'cookieyes';
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  private resetCookies(): void {
    document.cookie = 'hasSeenOffer=false';
    document.cookie = 'hasSeenAdditionalOffer=false';
    document.cookie = 'hasSeenErrorOffer=false';
    document.cookie = 'I_already_saw_the_second_offer=false';
  }

  private analyticEvent(): void {
    this.analyticService
      .analyticWebPoint(AnalyticTypesEnum.WebEmailOpened, {})
      .subscribe();
  }

  private getCookie(name: string): string {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
      )
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  private getSubscriptions(): void {
    this.subscriptionService.getAllSubscriptions().pipe().subscribe();
  }
}
