import { NgModule } from '@angular/core';
import { StoreModule as NgRxStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment.prod';
import { appReducers } from './reducers/app.reducers';
import { SubscriptionEffects } from './effects/subscription.effects';
import { UserInfoEffects } from './effects/user-info.effects';
import { ProductEffects } from './effects/product.effects';
import { PaymentLinkEffects } from './effects/payment-links.effects';
import { VariantEffects } from './effects/variant.effects';

@NgModule({
  imports: [
    NgRxStoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      SubscriptionEffects,
      UserInfoEffects,
      ProductEffects,
      PaymentLinkEffects,
      VariantEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class StoreModule {}
