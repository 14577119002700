import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { AnalyticService } from 'src/app/common/services/analytic.service';
import { AnalyticTypesEnum } from 'src/app/common/enums/analytic-types.enum';
import { StorageService } from 'src/app/common/services/storage.service';
import { SOURCES } from './common/constants/sourses';
import { AppService } from './app.service';
import { DEFAULT_VARIANT } from './common/constants/default-variant';
import { Variant } from './common/models/variant';
import { SubscriptionService } from './common/services/subscription.service';
import { SetSource } from './store/actions/user-info.actions';
import { UpdateAndChangeVariant } from './store/actions/variant.actions';
import { selectAllData } from './store/selectors/app.selector';
import { IAppState } from './store/states/app.state';
import { LanguageService } from './common/services/language.service';
import { LOCALIZATIONS } from './common/constants/languages';

declare const window: any;

@Component({
  selector: 'ik-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  public allDataObservable = this.store.pipe(select(selectAllData));
  public allData: IAppState | null = null;
  private destroy$ = new Subject<void>();
  private readonly emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  constructor(
    private readonly appService: AppService,
    private readonly translate: TranslateService,
    private readonly store: Store<IAppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly subscriptionService: SubscriptionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly analyticService: AnalyticService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly languageService: LanguageService
  ) {}

  public ngOnInit(): void {
    // Инициализация приложения
    this.initializeApp();
    // Подписка на изменения в сторе
    this.subscribeToStore();
    // Подписка на изменения маршрута
    this.subscribeToRouteChanges();
    // Подписка на изменения языка
    this.subscribeToLangChanges();
  }

  public ngAfterContentInit(): void {
    // Вставка шрифтов после инициализации контента
    this.appService.insertFonts();
  }

  public ngOnDestroy(): void {
    // Очистка подписок при уничтожении компонента
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeApp(): void {
    // Начальная настройка приложения
    this.appService.setVariant();
    this.appService.updateTag();
    this.appService.checkCookie();
    this.monitorTranslationReady();
    // Используем timer вместо setTimeout для дефолтного варианта
    timer(2500)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.ensureDefaultVariant());
  }

  private ensureDefaultVariant(): void {
    // Установка дефолтного варианта, если он не задан
    if (!this.allData?.variant.variant) {
      this.store.dispatch(new UpdateAndChangeVariant(DEFAULT_VARIANT));
    }
  }

  private monitorTranslationReady(): void {
    // Отслеживание готовности перевода
    this.translate.stream('Button_Continue')
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value !== 'Button_Continue') {
          this.hideLoader();
        }
      });
  }

  private hideLoader(): void {
    // Скрытие загрузчика
    const loader = this.document.getElementById('row');
    if (loader) {
      loader.style.opacity = '0';
      // Используем delay вместо setTimeout для анимации
      timer(500)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => (loader.style.display = 'none'));
    }
  }

  private subscribeToRouteChanges(): void {
    // Подписка на события маршрутизации
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => route.firstChild ?? route),
        takeUntil(this.destroy$),
        first()
      )
      .subscribe(route => this.handleRouteParams(route));
  }

  private handleRouteParams(route: ActivatedRoute): void {
    // Обработка параметров маршрута
    const params = route.snapshot.params;
    const param1 = params.param1;
    const param2 = params.param2;
    const param3 = params.param3;

    const isLang = (code: string) => LOCALIZATIONS.some(l => l.code === code);
    const isSource = (source: string) => SOURCES.has(source);

    if (!this.translate.currentLang) {
      this.languageService.setInitialLang(param1, isLang(param1), this.allData?.variant.variant || null);
    }

    [param1, param2].filter(isSource).forEach(source => {
      this.store.dispatch(new SetSource(source));
    });

    [param1, param2, param3].filter(Boolean).forEach(param => {
      if (this.emailRegex.test(param)) {
        this.appService.authByEmail(param);
      }
    });
  }

  private subscribeToStore(): void {
    // Подписка на обновления стора
    this.allDataObservable
      .pipe(
        takeUntil(this.destroy$),
        filter(data => !!data?.variant.variant),
        tap(data => this.handleStoreUpdate(data!)),
        first(),
        tap(() => this.trackInitialAnalytics())
      )
      .subscribe();
  }

  private handleStoreUpdate(data: IAppState): void {
    // Обработка обновлений данных из стора
    const variant = data.variant.variant!;
    if (!this.allData?.variant.variant) {
      this.applyInitialVariantSettings(variant);
      this.fetchSubscriptionData(variant);
    }
    this.allData = data;
    this.cdr.markForCheck();
  }

  private applyInitialVariantSettings(variant: Variant): void {
    // Применение начальных настроек варианта
    if (variant.parameters.alternativeTranslate && window.location.pathname.includes('/flow_es')) {
      this.translate.use('es-MX2');
    }
    this.checkLangIgnoreList(variant);
    this.storageService.setVariantName(variant.name);
    if (variant.parameters.needBanner) {
      this.appService.insertBanner();
    }
  }

  private fetchSubscriptionData(variant: Variant): void {
    // Получение данных подписки с динамической задержкой
    const delayMs = window.location.pathname.length > 2 ? 0 : 3000;
    timer(delayMs)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.subscriptionService.getFlagAndProducts(variant).pipe(takeUntil(this.destroy$)).subscribe();
          this.subscriptionService.getLifetimeProducts().pipe(takeUntil(this.destroy$)).subscribe();
          this.subscriptionService.getPaypalDisabled().pipe(takeUntil(this.destroy$)).subscribe();
          this.subscriptionService.getCardHolderVisible().pipe(takeUntil(this.destroy$)).subscribe();
          this.subscriptionService.getApplePurchaseValue().pipe(takeUntil(this.destroy$)).subscribe();
        })
      )
      .subscribe();
  }

  private trackInitialAnalytics(): void {
    // Отслеживание начальной аналитики
    const time = (new Date().getTime() - window.timerStart).toString();
    const properties = {
      download_time: time,
      download_start_time: new Date(window.timerStart).toString(),
      download_end_time: new Date().toString(),
    };
    this.analyticService.analyticWebPoint(AnalyticTypesEnum.WebOnboardingView, properties).subscribe();
  }

  private subscribeToLangChanges(): void {
    // Подписка на изменения языка
    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.allData?.variant.variant) {
          this.checkLangIgnoreList(this.allData.variant.variant);
        }
        this.document.documentElement.lang = this.translate.currentLang;
      });
  }

  private checkLangIgnoreList(variant: Variant): void {
    // Проверка списка игнорируемых языков
    const disabledLocales = variant.parameters?.listDisabledLocal;
    if (disabledLocales?.includes(this.translate.currentLang)) {
      this.translate.use('en');
    }
  }
}