import { EUserInfoActions, UserInfoActions } from '../actions/user-info.actions';
import { initialUserInfoState, IUserInfoState } from '../states/user-info.state';

export function userInfoReducers(
  state = initialUserInfoState,
  action: UserInfoActions,
): IUserInfoState {
  switch (action.type) {
    case EUserInfoActions.GetUserInfoPaymentSuccess: {
      return {
        ...state,
        payment: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoEmailSuccess: {
      return {
        ...state,
        email: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoProductSuccess: {
      return {
        ...state,
        product: action.payload.product,
        offerView: action.payload.offerView,
      };
    }

    case EUserInfoActions.GetUserInfoChallengeSuccess: {
      return {
        ...state,
        challenge: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoAuthSuccess: {
      return {
        ...state,
        auth: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoBrowserIDSuccess: {
      return {
        ...state,
        browserID: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoPromoterSuccess: {
      return {
        ...state,
        promoter: action.payload,
      };
    }

    case EUserInfoActions.GetUserInfoUpgradeSuccess: {
      return {
        ...state,
        upgradeSubscription: action.payload,
      };
    }

    case EUserInfoActions.UpdateDeepLinksSuccess: {
      return {
        ...state,
        deepLinks: action.payload,
      };
    }

    case EUserInfoActions.UpdateUserInfoPurchases: {
      return {
        ...state,
        purchases: action.payload,
      };
    }

    case EUserInfoActions.UpdateCounter: {
      return {
        ...state,
        counter: action.payload,
      };
    }

    case EUserInfoActions.UpdateChildName: {
      return {
        ...state,
        childName: action.payload,
      };
    }

    case EUserInfoActions.UpdateChildAge: {
      return {
        ...state,
        childAge: action.payload.replace(/[\u2010\u2011\u2012\u2013\u2014\u2212\u2015]/g, '-'),
      };
    }

    case EUserInfoActions.UpdateInterests1: {
      return {
        ...state,
        interests1: action.payload,
      };
    }

    case EUserInfoActions.UpdateInterests2: {
      return {
        ...state,
        interests2: action.payload,
      };
    }

    case EUserInfoActions.ChangeSubscriptionSwitch: {
      return {
        ...state,
        subscriptionWasSwithed: action.payload,
      };
    }

    case EUserInfoActions.UpdateAnswersMap: {
      return {
        ...state,
        answersMap: state.answersMap.set(action.payload.category, action.payload.answer),
      };
    }

    case EUserInfoActions.UpdateAnswersKeyMap: {
      return {
        ...state,
        answersKeyMap: state.answersKeyMap.set(action.payload.key, action.payload.answer),
      };
    }

    case EUserInfoActions.SetSource: {
      return {
        ...state,
        source: action.payload,
      };
    }

    case EUserInfoActions.UpdateNewFlow: {
      return {
        ...state,
        newFlow: action.payload,
      };
    }

    default : return state;
  }
}
