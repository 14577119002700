import { NgModule } from '@angular/core';


@NgModule({
  imports: [
    
  ],
})
export class ApiErrorsModule {
}
