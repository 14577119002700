import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from 'src/app/store/states/app.state';
import { StorageService } from './storage.service';

export interface AnalyticProperties {
  Email: string;
  userAgent: string;
  os: string;
  Browser_ID: string;
  traffic_source: string;
  promoter: string;
  referrer: string;
  link: string;
  ABTest: string;
  Variant: string;
  ab_test_start_event: string;
  ab_test_start_name: string;
  campaign_id: string | null;
  adset_id: string | null;
  ad_id: string | null;
  placement: string | null;
  site_source_name: string | null;
  utm_medium: string | null;
  utm_source: string | null;
  utm_campaign: string | null;
  utm_crm_type: string | null;
  utm_content: string | null;
  utm_term: string | null;
  utm_crm_email: string | null;
  inner_height: string;
  inner_width: string;
  onboarding: string;
  payment_screen: string;
  subscription_id: string;
  child_age: string;
  failed_reason?: string;
  browser_lang: string;
  used_lang: string;
  product_name?: string;
  order_id?: string;
}

@Injectable()
export class AnalyticPropertiesFactory {
  constructor(
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  public buildProperties(allData: IAppState): AnalyticProperties {
    const params = this.activatedRoute.snapshot.queryParamMap;
    const variant = allData.variant.variant;

    return {
      Email: allData.userInfo.email || '',
      userAgent: navigator.userAgent,
      os: this.getOS(),
      Browser_ID: this.storageService.getUserId(),
      traffic_source: this.getTrafficSource(allData),
      promoter: allData.userInfo.promoter || '',
      referrer: document.referrer,
      link: window.location.href,
      ABTest: variant ? variant.abtest || '' : '',
      Variant: variant ? variant.name : '',
      ab_test_start_event: variant ? variant.ab_test_start_event || '' : '',
      ab_test_start_name: variant?.parameters ? variant.parameters.ab_test_start_name : '',
      campaign_id: params.get('campaign_id'),
      adset_id: params.get('adset_id'),
      ad_id: params.get('ad_id'),
      placement: params.get('utm_medium'),
      site_source_name: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_source: params.get('utm_source'),
      utm_campaign: params.get('utm_campaign'),
      utm_crm_type: params.get('utm_crm_type'),
      utm_content: params.get('utm_content'),
      utm_term: params.get('utm_term'),
      utm_crm_email: params.get('utm_crm_email'),
      inner_height: window.innerHeight.toString(),
      inner_width: window.innerWidth.toString(),
      onboarding: variant ? variant.onboarding.name : '',
      payment_screen: variant ? variant.payment_screen.name : '',
      subscription_id:
        allData.subscription.subscriptions?.length
          ? allData.subscription.subscriptions[0].subscriptionId
          : '',
      child_age: allData.userInfo.childAge || '',
      failed_reason: '',
      browser_lang: this.translate.getBrowserLang() || '',
      used_lang: this.translate.currentLang,
    };
  }

  private getOS(): string {
    const userAgent = navigator.userAgent;
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    const isMobile = toMatch.some(toMatchItem => userAgent.match(toMatchItem));

    if (!isMobile) return 'Web';
    if (/android/i.test(userAgent)) return 'Android';
    if (/iPad|iPhone|iPod/.test(userAgent)) return 'iOS';
    return 'Other mobile';
  }

  private getTrafficSource(allData: IAppState): string {
    if (allData.userInfo.source) return allData.userInfo.source;
    if (!allData.variant.variant) return '';

    const variantName = allData.variant.variant.name.toLowerCase();
    if (variantName.includes('email') || variantName.includes('crm')) {
      return 'email';
    }
    if (window.location.pathname.includes('basic')) {
      return 'web';
    }
    return 'campaign';
  }
}
