import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCALIZATIONS } from '../constants/languages';
import { Variant } from '../models/variant';

@Injectable()
export class LanguageService {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('en'); // Устанавливаем язык по умолчанию
  }

  public setInitialLang(langFromRoute: string | null, isLang: boolean, variant: Variant | null): void {
    const pathname = window.location.pathname;

    // Карта путей и языков
    const pathToLangMap: { [key: string]: string } = {
      '/program_1': 'en',
      '/flow_001': 'en',
      '/flow_002': 'en',
      '/flow_us_': 'en',
      '/tiktok': 'en',
      '/new_product': 'en',
      '/flow_ww': 'en',
      '/flow_ca': 'en',
      '/flow_de_001': 'de',
      '/inst-de': 'de',
      '/flow_fr': 'fr',
      '/inst-fr': 'fr',
      '/flow_it': 'it',
      '/inst-it': 'it',
      '/flow_br_': 'pt-BR',
      '/inst-pt-br': 'pt-BR',
      '/inst-es': 'es-MX',
      '/flow_es': 'es-MX', // Дополнительная логика для es-MX2 ниже
      '/want_to_know_tid': 'kl',
    };

    // Проверяем, есть ли путь в карте
    for (const [path, lang] of Object.entries(pathToLangMap)) {
      if (pathname.includes(path) && pathname.indexOf('/program_1_local') === -1) {
        if (lang === 'es-MX' && variant?.parameters.alternativeTranslate) {
          this.translate.use('es-MX2');
        } else {
          this.translate.use(lang);
        }
        return;
      }
    }

    // Если путь не найден, проверяем параметр из маршрута
    if (langFromRoute && isLang) {
      const usageLang = this.getExistLang(langFromRoute);
      this.translate.use(usageLang);
      return;
    }

    // Если ничего не подошло, используем язык браузера или 'en'
    const browserLang = this.translate.getBrowserLang() || 'en';
    const usageLang = this.getExistLang(browserLang);
    const supportedLangs = LOCALIZATIONS.map(lang => lang.code);
    const langRegex = new RegExp(`^(${supportedLangs.join('|')})`);
    this.translate.use(
      browserLang.match(langRegex)
        ? browserLang
        : usageLang
    );
  }

  private getExistLang(str: string): string {
    const foundedLang = LOCALIZATIONS.find(item => item.code.indexOf(str) !== -1);
    return foundedLang ? foundedLang.code : 'en';
  }
}
