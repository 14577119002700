export enum AnalyticTypesEnum {
  WebOnboardingView = 'WebOnboardingView',
  WebAgeScreenVisible = 'WebAgeScreenVisible',
  WebOnboardingStageCompleted = 'WebOnboardingStageCompleted',
  WebOnboardingScroll = 'WebOnboardingScroll',
  // события в процессе авторизации и оплаты
  WebEmailEntered = 'WebEmailEntered',
  WebEmailOpened = 'WebEmailOpened',

  WebPayOfferView = 'WebPayOfferView',

  WebPromoButtonClick = 'WebPromoButtonClick',
  WebStartSpinner = 'WebStartSpinner',
  WebTurnMore = 'WebTurnMore',
  WebClaimOffer = 'WebClaimOffer',
  WebExitFortune = 'WebExitFortune',

  WebAttentionOpen = 'WebAttentionOpen',
  WebAttentionBack = 'WebAttentionBack',
  WebAttentionLeave = 'WebAttentionLeave',

  WebPayVideoOpened = 'WebPayVideoOpened',
  WebOfferTrialView = 'WebOfferTrialView',
  WebOfferTrialSelected = 'WebOfferTrialSelected',
  WebPaySwitcherClicked = 'WebPaySwitcherClicked',
  WebPaySubscribeButtonClickedLoaded = 'WebPaySubscribeButtonClickedLoaded',
  WebPaypalReady = 'WebPaypalReady',
  WebCardReady = 'WebCardReady',
  WebPaySubscribeButtonClicked = 'WebPaySubscribeButtonClicked',
  WebPayFiveMinutesClosed = 'WebPayFiveMinutesClosed',
  WebPayFirstOfferClosed = 'WebPayFirstOfferClosed',
  WebPayAdditionalOfferView = 'WebPayAdditionalOfferView',
  WebPayAdditionalOfferClicked = 'WebPayAdditionalOfferClicked',
  WebPayAdditionalOfferValidated = 'WebPayAdditionalOfferValidated',
  WebPayOfferSubscribed = 'WebPayOfferSubscribed',
  WebPayOfferValidated = 'WebPayOfferValidated',
  WebPayOfferValidationFailed = 'WebPayOfferValidationFailed',
  WebPayCrossgradeAccepted = 'WebPayCrossgradeAccepted',
  WebPayCrossgradeDeclined = 'WebPayCrossgradeDeclined',
  WebPayCrossgradeError = 'WebPayCrossgradeError',

  WebSpecoffer302View = 'WebSpecoffer302View',
  WebSpecoffer302BuyClick = 'WebSpecoffer302BuyClick',
  WebSpecoffer302Subscribed = 'WebSpecoffer302Subscribed',
  WebSpecoffer302SubscribedFailed = 'WebSpecoffer302SubscribedFailed',
  WebPay403Failed = 'WebPay403Failed',

  WebOneClickPaymentSuccess = 'WebOneClickPaymentSuccess',
  WebOneClickPaymentFailed = 'WebOneClickPaymentFailed',

  WebSpecofferView = 'WebSpecofferView',
  WebPaySpecofferClicked = 'WebPaySpecofferClicked',

  WebPayAdditionalOfferValidated_SpecialBack = 'WebPayAdditionalOfferValidated_SpecialBack',
  WebPayAdditionalOfferValidated_Special302 = 'WebPayAdditionalOfferValidated_Special302',

  WebPayAdditionalOfferValidationFailed = 'WebPayAdditionalOfferValidationFailed',
  WebPayAdditionalOfferValidationFailed_Special302 = 'WebPayAdditionalOfferValidationFailed_Special302',
  WebPayAdditionalOfferValidationFailed_SpecialBack = 'WebPayAdditionalOfferValidationFailed_SpecialBack',

  WebReasonCloseOpen = 'WebReasonCloseOpen',
  WebReasonCloseAnswer = 'WebReasonCloseAnswer',

  TrialSwitchedOn = 'TrialSwitchedOn',
  TrialSwitchedOff = 'TrialSwitchedOff',
  

  WebFirstOfferBackClick = 'WebFirstOfferBackClick',
  WebFirstOfferCloseBrowser = 'WebFirstOfferCloseBrowser',
  WebAddtionalOfferBackClick = 'WebAddtionalOfferBackClick',
  Web302ErrorAdditionalOffer = 'Web302ErrorAdditionalOffer',

  WebDiscountView = 'WebDiscountView',
  WebDiscountApply = 'WebDiscountApply',

  // события чекаута
  WebPayCheckoutView = 'WebPayCheckoutView',
  WebPayCheckoutReady = 'WebPayCheckoutReady',
  WebPayCheckoutClosed = 'WebPayCheckoutClosed',
  WebPayCheckoutError = 'WebPayCheckoutError',
  WebPayCheckoutClick = 'WebPayCheckoutClick',

  // Confirm email
  WebConfirmEmailShow = 'WebConfirmEmailShow',
  WebConfirmEmailChange = 'WebConfirmEmailChange',

  // события с уже активной подпиской
  WebE4KAccepted = 'WebE4KAccepted',
  WebE4KDeclined = 'WebE4KDeclined',
  WebE4KOfferView= 'WebE4KOfferView',
  WebE4KSubscribed = 'WebE4KSubscribed',
  WebE4KFailed = 'WebE4KFailed',

  WebSPAccepted = 'WebSPAccepted',
  WebSPDeclined = 'WebSPDeclined',
  WebSPOfferView= 'WebSPOfferView',
  WebSPSubscribed = 'WebSPSubscribed',
  WebSPFailed = 'WebSPFailed',

  WebPayStoreButtonVisible = 'WebPayStoreButtonVisible',
  WebPayStoreDownloadButtonClicked  = 'WebPayStoreDownloadButtonClicked ',
  WebPayReturn = 'WebPayReturn',
  WebPayCancel = 'WebPayCancel',
  WebPayRefund = 'WebPayRefund',
  WebPayCancelConfirmation = 'WebPayCancelConfirmation',
  WebPayRefundSubscriptionSelected = 'WebPayRefundSubscriptionSelected',
  WebPayRefundConfirmation = 'WebPayRefundConfirmation',

  WebPayPrintablesOfferView = 'WebPayPrintablesOfferView',
  WebPayPrintablesClick = 'WebPayPrintablesClick',
  WebPayPrintablesBuyClick = 'WebPayPrintablesBuyClick',
  WebPayPrintablesOfferSubscribed = 'WebPayPrintablesOfferSubscribed',
  WebPayPrintablesOfferSubscribedFailed = 'WebPayPrintablesOfferSubscribedFailed',
  WebPayPrintablesShowAgain = 'WebPayPrintablesShowAgain',
  WebPayPrintablesClose = 'WebPayPrintablesClose',

  WebPayOxfordOfferView = 'WebPayOxfordOfferView',
  WebPayOxfordShowAgain = 'WebPayOxfordShowAgain',
  WebPayOxfordOfferSubscribeButtonClicked = 'WebPayOxfordOfferSubscribeButtonClicked',
  WebPayOxfordOfferSubscribed = 'WebPayOxfordOfferSubscribed',
  WebPayOxfordOfferSubscribedFailed = 'WebPayOxfordOfferSubscribedFailed',

  WebInfoView = 'WebInfoView',

  WebLinkToStoreCreated = 'WebLinkToStoreCreated',
}
