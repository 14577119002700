import { LanguageService } from './language.service';
import { AnalyticService } from './analytic.service';
import { AuthService } from './auth.service';
import { FacebookPixelService } from './fbpixel.service';
import { LifeTimeService } from './lifetime.service';
import { ScreenService } from './screen.service';
import { SolidService } from './solid.service';
import { SputnikService } from './sputnik.service';
import { StorageService } from './storage.service';
import { SubscribeErrorMessagesService } from './subscribe-error-messages.service';
import { SubscriptionService } from './subscription.service';
import { AnalyticPropertiesFactory } from './analytic-properties.factory';

export const COMMON_SERVICES = [
  StorageService,
  AuthService,
  SubscribeErrorMessagesService,
  FacebookPixelService,
  SubscriptionService,
  ScreenService,
  AnalyticService,
  SputnikService,
  SolidService,
  LifeTimeService,
  LanguageService,
  AnalyticPropertiesFactory,
];
