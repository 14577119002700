import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/common/models/product';
import { SolidService } from 'src/app/common/services/solid.service';

@Injectable()
export class LifeTimeService {

  constructor(
    private readonly solidService: SolidService,
  ) {
  }

  public buyLifeTimeProducts(products: Product[], cardTokenOrderId: string, oneClickPayment = false): Observable<{ products: Product[], orderIds: string[] }> {
    return  this.solidService.buyLifeTimeProducts(products, cardTokenOrderId, oneClickPayment);
  }
}
