import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {

  // Список стран
  private countryList = new Set([
    'AUS', 'CAN', 'MEX', 'BRA', 'ARG', 'PER', 'DOM', 'CRI', 'URY', 'ECU',
    'PRY', 'PAN', 'BHS', 'BRB', 'PRI', 'ATG', 'GRD', 'ABW', 'DMA', 'HTI',
    'GLP', 'MTQ', 'AIA', 'MSR', 'TTO', 'JAM', 'GUY', 'CYM', 'VEN', 'CUW',
    'GTM', 'BOL', 'BMU', 'SLV', 'SUR', 'BLZ', 'NIC', 'HND', 'GUF',
  ]);

  // Список валют без десятичных знаков
  private currenciesWithoutDecimals = new Set(['IDR', 'ZAR', 'TRY', 'INR', 'THB', 'VND', 'KRW', 'HUF', 'PHP']);

  // Список валют с особым форматированием
  private specialCurrencies = new Set(['CAD', 'AUD']);

  constructor(private currencyPipe: CurrencyPipe) {}

  public transform(value: number, currency: string, display: string, country = 'USA'): any {
    // Обработка специальных валют (CAD, AUD)
    if (this.specialCurrencies.has(currency)) {
      return this.currencyPipe.transform(value, currency, 'symbol');
    }
  
    // Обработка валют без десятичных знаков
    if (this.currenciesWithoutDecimals.has(currency)) {
      return this.currencyPipe.transform(value, currency, 'symbol', '1.0-0');
    }

     // Обработка USD для стран из countryList
     if (this.countryList.has(country) && currency === 'USD') {
      if (!value) {
        return 'US$';
      }
      return `US$${(value * 1).toFixed(2)}`;
    }
  
    return this.currencyPipe.transform(value, currency, display);
  }

}
