import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsContainerComponent } from './container/notifications-container.component';

import { SnackBarComponent } from './snack-bar/snack-bar.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [
    SnackBarComponent,
    NotificationsContainerComponent
  ],
  exports: [
    NotificationsContainerComponent
  ]
})
export class NotificationsModule {}
