import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAllData } from 'src/app/store/selectors/app.selector';
import { IAppState } from 'src/app/store/states/app.state';
import { AnalyticProperties, AnalyticPropertiesFactory } from './analytic-properties.factory';

import { AnalyticTypesEnum } from './../enums/analytic-types.enum';
import { CheckoutStatus } from './../models/checkout-status';

@Injectable()
export class AnalyticService {

  private allDataObservable = this.store.pipe(select(selectAllData));

  private allData!: IAppState;

  private checkoutStatus = new CheckoutStatus('', false, false);

  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private propertiesFactory: AnalyticPropertiesFactory,
  ) {
    this.subscriptionStore();
  }

  public analyticWebPoint(
    name: AnalyticTypesEnum,
    properties: Partial<AnalyticProperties> | any = {}
  ): Observable<string> {
    console.log(name);

    // Используем фабрику для базовых свойств
    const baseProperties = this.propertiesFactory.buildProperties(this.allData);
    const combinedProperties: AnalyticProperties = { ...baseProperties, ...properties };

    // Дополнительные свойства в зависимости от события
    if (this.isEventNameAfterChoosingOffer(name)) {
      combinedProperties.product_name = this.allData.userInfo.product?.name || '';
    }

    if (this.isEventForCheckoutReady(name)) {
      this.changeCheckoutStatus(name, this.allData.userInfo.product!.name!);
    }

    if (this.isEventForOrderId(name)) {
      combinedProperties.order_id = this.allData.subscription.orderId || '';
    }

    if (name === AnalyticTypesEnum.WebPayCheckoutClosed) {
      this.checkoutStatus.opened = false;
    }

    // Приведение всех значений к строкам
    Object.keys(combinedProperties).forEach(key => {
      const value = combinedProperties[key as keyof AnalyticProperties];
      if (typeof value !== 'string') {
        try {
          combinedProperties[key as keyof AnalyticProperties] = value!.toString() || '';
        } catch {
          combinedProperties[key as keyof AnalyticProperties] = '';
        }
      }
    });

    if (combinedProperties.failed_reason) {
      combinedProperties.failed_reason = combinedProperties.failed_reason.substr(0, 1000);
    }

    const body = {
      events: [{
        name,
        type: 'web',
        source: 'PaymentSite',
        uid: this.allData.userInfo.auth ? this.getUserIdFromToken(this.allData.userInfo.auth.id_token) : '',
        time: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).getTime(),
        event_properties: combinedProperties,
      }],
    };

    return this.httpClient
      .post<{ status: string }>('/api/event', body)
      .pipe(map(response => response.status));
  }

  public addUTMParamsIntoCookie(): void {
    const map = this.activatedRoute.snapshot.queryParamMap;
    if (this.getCookie('campaign_id')) {
      return;
    }

    document.cookie = `campaign_id=${ map.get('campaign_id') || '' }`;
    document.cookie = `adset_id=${ map.get('adset_id') || '' }`;
    document.cookie = `ad_id=${ map.get('ad_id') || '' }`;
    document.cookie = `placement=${ map.get('utm_medium') || '' }`;
    document.cookie = `utm_source=${ map.get('utm_source') || '' }`;
  }

  private getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  private getUserIdFromToken(tokenOld: string): string {
    const token = `${ tokenOld }`;
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('').map(c => {
        return `%${ (`00 ${ c.charCodeAt(0).toString(16) }`).slice(-2) }`;
    }).join(''));

    const obj = JSON.parse(base64);
    return obj.sub ? obj.sub : '';
  }

  private subscriptionStore(): void {
    this.allDataObservable
      .subscribe(data => this.allData = data!);
  }

  private isEventNameAfterChoosingOffer(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayOfferValidated :
      case AnalyticTypesEnum.WebPayOfferValidationFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferClicked :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated :
      case AnalyticTypesEnum.WebPaySubscribeButtonClicked :
      case AnalyticTypesEnum.WebPaySubscribeButtonClickedLoaded :
      case AnalyticTypesEnum.WebPayCrossgradeAccepted :
      case AnalyticTypesEnum.WebPayCrossgradeDeclined :
      case AnalyticTypesEnum.WebPayStoreButtonVisible :
      case AnalyticTypesEnum.WebPayStoreDownloadButtonClicked :
      case AnalyticTypesEnum.WebPayOxfordOfferView :
      case AnalyticTypesEnum.WebPayOxfordShowAgain :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribeButtonClicked :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribed :
      case AnalyticTypesEnum.WebCardReady :
      case AnalyticTypesEnum.WebPaypalReady :
      case AnalyticTypesEnum.WebPayOxfordOfferSubscribedFailed :
      case AnalyticTypesEnum.WebSpecoffer302BuyClick :
      case AnalyticTypesEnum.WebSpecoffer302Subscribed :
      case AnalyticTypesEnum.WebSpecoffer302SubscribedFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated_SpecialBack :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated_Special302 :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed_Special302 :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidationFailed_SpecialBack :
      case AnalyticTypesEnum.WebPayCheckoutClick :
      case AnalyticTypesEnum.WebPayCheckoutError :
      case AnalyticTypesEnum.WebPayCheckoutReady :
      case AnalyticTypesEnum.WebPayCheckoutView : {
        return true;
      }

      default: return false;
    }
  }

  private isEventForCheckoutReady(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayCheckoutView :
      case AnalyticTypesEnum.WebCardReady :
      case AnalyticTypesEnum.WebPaypalReady : {
        return true;
      }

      default: return false;
    }
  }

  private isEventForOrderId(name: AnalyticTypesEnum): boolean {
    switch (name) {
      case AnalyticTypesEnum.WebPayOfferValidated :
      case AnalyticTypesEnum.WebPayAdditionalOfferValidated : {
        return true;
      }

      default: return false;
    }
  }

  private changeCheckoutStatus(name: AnalyticTypesEnum, productName: string): void {
    switch (name) {
      case AnalyticTypesEnum.WebPayCheckoutView : {
        this.checkoutStatus.productName === productName ? this.checkoutStatus.opened = true : this.checkoutStatus = new CheckoutStatus(productName, true, false);
        setTimeout(() => this.checkCheckoutStatus(), 5000);
        break;
      }
      case AnalyticTypesEnum.WebCardReady : {
        this.checkoutStatus.productName === productName ? this.checkoutStatus.cardReady = true : this.checkoutStatus = new CheckoutStatus(productName, false, true);
        break;
      }
      case AnalyticTypesEnum.WebPaypalReady : {
        break;
      }
    }
    if (this.checkoutStatus.opened && this.checkoutStatus.cardReady) {
      this.analyticWebPoint(AnalyticTypesEnum.WebPayCheckoutReady, {}).subscribe();
    }
  }

  private checkCheckoutStatus(): void {
    if (this.checkoutStatus.opened && !this.checkoutStatus.cardReady && !this.allData!.product.paypalDisabled) {
      // tslint:disable-next-line: variable-name
      const failed_reason = `${ !this.checkoutStatus.cardReady ? 'card is not ready' : ''}`;
      this.analyticWebPoint(AnalyticTypesEnum.WebPayCheckoutError, { failed_reason }).subscribe();
    }
  }
}
