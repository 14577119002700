export interface VariantConfig {
  path: string;
  variantType?: string; // Для GetVariantByType
  variantId?: string;   // Для GetSpecialVariantFromServer
}

// Варианты для GetVariantByType
export const VARIANT_BY_TYPE_CONFIG: VariantConfig[] = [
  { path: '/new_product', variantType: 'new_product' },
  { path: '/inst-es', variantType: 'inst-es' },
  { path: '/inst-de', variantType: 'inst-de' },
  { path: '/inst-it', variantType: 'inst-it' },
  { path: '/inst-fr', variantType: 'inst-fr' },
  { path: '/inst-pt-br', variantType: 'inst-pt-br' },
  { path: '/inst', variantType: 'inst' },
  { path: '/fb-kids-education', variantType: 'fb-kids-education' },
  { path: '/program_1_local', variantType: 'program_1_local' },
  { path: '/better_way', variantType: 'better_way' },
  { path: '/new_way', variantType: 'new_way' },
  { path: '/funandeasy', variantType: 'funandeasy' },
  { path: '/tiktok', variantType: 'tiktok' },
  { path: '/englishforkids', variantType: 'englishforkids' },
  { path: '/newplans-program-1', variantType: 'newplans_program_1' },
  { path: '/flow_us_11', variantType: 'flow_us_11' },
  { path: '/flow_fr', variantType: 'flow_fr' },
  { path: '/flow_it', variantType: 'flow_it' },
  { path: '/flow_us_10', variantType: 'flow_us_10' },
  { path: '/flow_us_21', variantType: 'flow_us_21' },
  { path: '/flow_us_3', variantType: 'flow_us_3' },
  { path: '/flow_ca', variantType: 'flow_ca' },
  { path: '/flow_ww', variantType: 'flow_ww' },
  { path: '/flow_001', variantType: 'flow_001' },
  { path: '/flow_002', variantType: 'flow_002' },
  { path: '/flow_de_001', variantType: 'flow_de_001' },
  { path: '/flow_br_001', variantType: 'flow_br_001' },
  { path: '/flow_br_002', variantType: 'flow_br_002' },
  { path: '/flow_es', variantType: 'flow_es' },
  { path: '/montessori', variantType: 'montessori' },
  { path: '/learning-plan', variantType: 'learning-plan' },
];

// Варианты для GetSpecialVariantFromServer
export const VARIANT_FROM_SERVER_CONFIG: VariantConfig[] = [
  { path: '/basic', variantId: '3d47452c-1fd9-4931-8170-6f42634e88b7' },
  { path: '/checkout', variantId: 'cb0eb3d9-ab49-4d69-8d34-0afd10115b7f' },
  { path: '/login', variantId: 'cb0eb3d9-ab49-4d69-8d34-0afd10115b7f' },
  { path: '/75_new_users_v2', variantId: '05d6f64f-3c29-4a3e-a1fb-55f5c3de366d' },
  { path: '/75_new_users', variantId: '9d20348c-babc-4362-9ec0-c2a89f8e0589' },
  { path: '/year_discount', variantId: 'c8ca3bc1-64a4-4233-9a6f-9575d0d6b1b9' },
  { path: '/learning-games-6-months', variantId: 'e0230f60-229e-41ce-8c3a-9002bc82330a' },
  { path: '/lg-6-months', variantId: '0f32fa15-d996-4e8b-a556-690d2bf27e4b' },
  { path: '/ftc', variantId: 'b110765c-3331-476d-a24c-1329b87af0d5' },
  { path: '/internal_test_link', variantId: '18a587c7-c99a-4958-83b4-3c65a9455671' },
  { path: '/pm', variantId: 'd0ffc2f3-9d99-4481-9ce9-b129fda06ae5' },
  { path: '/personalized_program_v1', variantId: 'fdf211fc-9f40-4867-a2dd-966633c4c8eb' },
  { path: '/renewal', variantId: '44df5442-0841-4668-97c1-30a9d824007e' },
  { path: '/hey_halloween', variantId: '457694c1-1d10-40cf-8b4d-79cc325b0da4' },
  { path: '/halloween', variantId: '485e8fe2-3a1a-43d0-af98-f7540718a108' },
  { path: '/hey_blackfriday', variantId: '31602f21-1514-4358-8afc-d1cdf35168b3' },
  { path: '/blackfriday', variantId: 'acbaaf67-7319-47f1-a41d-de5d4a05678a' },
  { path: '/wow_blackfriday', variantId: 'a9311811-c483-4b0c-bcd2-0935e7ce21e0' },
  { path: '/educationalappstore', variantId: 'ae238d5f-d20e-44d9-922d-272acc781f44' },
  { path: '/newplans_trial', variantId: '536f30b6-1a68-4f21-b2ef-fcf1cfd78e64' },
  { path: '/sub_cancelled', variantId: 'a37e1978-9a77-4ee5-b607-0623040ce3f3' },
  { path: '/supspecialoffer', variantId: 'd18ed3b2-a3d3-4f3f-aad0-4986c2dc48c0' },
  { path: '/pay', variantId: 'cb0eb3d9-ab49-4d69-8d34-0afd10115b7f' }, // Default для /pay
];

// Дефолтный вариант
export const DEFAULT_VARIANT_ID = '4dcc5e2b-83fb-4699-beb9-e8a045fa2af0';
